html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family:
    'Noto Sans JP',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

body.is-modal-open {
  overflow: hidden;
}

:root {
  scroll-behavior: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: none;
}

button {
  background: none;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
}

fieldset {
  border: none;
}
